
import { sha512 }   from "./vendor/sha-x.js";
import B_REST_Error from "./B_REST_Error.js";



export default class B_REST_Utils
{
	static get LOCAL_STORAGE_PREFIX()            { return "bREST:";            }
	static get LOCAL_STORAGE_PREFIX_PERSISTENT() { return "bREST-persistent:"; }
	static get LOCAL_STORAGE_UNDEFINED()         { return "<undefined>";       }
	static get LOCAL_STORAGE_NULL()              { return "<null>";            }
	static get LOCAL_STORAGE_TRUE()              { return "<true>";            }
	static get LOCAL_STORAGE_FALSE()             { return "<false>";           }
	
	static get PWD_FRONTEND_TAG() { return "<intermediate>"; } //Must match server's CryptoUtils::PWD_FRONTEND_TAG
	
	static get CONTENT_TYPE_ANYTHING()  { return "<anything>";          }
	static get CONTENT_TYPE_EMPTY()     { return "<empty>";             } //For #204
	static get CONTENT_TYPE_TEXT()      { return "text/plain";          }
	static get CONTENT_TYPE_CSV()       { return "text/csv";            }
	static get CONTENT_TYPE_HTML()      { return "text/html";           }
	static get CONTENT_TYPE_JSON()      { return "application/json";    }
	static get CONTENT_TYPE_IMAGE()     { return "image/*";             }
	static get CONTENT_TYPE_PDF()       { return "application/pdf";     }
	static get CONTENT_TYPE_FORM_DATA() { return "multipart/form-data"; }
	
	static get FLAGS_ON_ERR_SHOW_NATIVE_ALERT_NEVER()  { return null;     }
	static get FLAGS_ON_ERR_SHOW_NATIVE_ALERT_ONCE()   { return "once";   }
	static get FLAGS_ON_ERR_SHOW_NATIVE_ALERT_ALWAYS() { return "always"; }
	
	static get DT_LOWEST_JS_YEAR()    { return 1896;     } //Any lower than "1896-01-01" and parts of the date / mins / timezone starts to break
	static get DT_SECS_IN_AVG_YEAR()  { return 31536000; } // 60*60*24*365
	static get DT_SECS_IN_AVG_MONTH() { return 2592000;  } // 60*60*24*30
	static get DT_SECS_IN_DAY()       { return 86400;    } // 60*60*24
	static get DT_SECS_IN_HOUR()      { return 3600;     } // 60*60
	static get DT_SECS_IN_MINUTE()    { return 60;       } // 60
	static get DT_MSECS_IN_MINUTE()   { return 60000;    } // 1000*60
	static get DT_MSECS_IN_SECOND()   { return 1000;     } // 1000*1
	static get DT_MSECS_IN_DAY()      { return 86400000; } // 1000*60*60*24
	
	static get ELLIPSIS_PLACEHOLDER_END() { return " [...]"; } //For string_ellipsis()
	
	
	
	//Flags - IMPORTANT: Must start at false, in case some happens before app config is read (in prod)
	static flags_onErr_breakpoint            = false;                                              //Adds a "debugger" in throwEx()
	static flags_onErr_showNativeAlert       = B_REST_Utils.FLAGS_ON_ERR_SHOW_NATIVE_ALERT_NEVER;  //One of FLAGS_ON_ERR_SHOW_NATIVE_ALERT_x. If throwEx() should dump the err in a native alert(). Shouldn't do that in prod
	static flags_onErr_overlayDomTree        = false;                                              //Ex when we detect an exception was thrown from a specific dom elem, put an overlay around it to help trace it. NOTE: For now, we only use it in B_REST_VueApp_base::Vue.config.warnHandler(), because otherwise we don't seem to have access to "where" funcs are being called from
	static flags_console_todo                = false;                                              //For console_todo()
	static flags_console_info                = false;                                              //For console_info()
	static flags_console_warn                = false;                                              //For console_warn()
	static flags_console_error               = false;                                              //For console_error()
	static flags_console_error_addStackTrace = false;                                              //For console_error(). Note that it's only relevant if we run this in Node (ex for the generator)
	
	//Static vars
	static _past_todos                                 = [];    //Arr of strings for console_todo()
	static _throwEx_count                              = 0;     //Nb of errs so far
	static _dt_device_isDaylightSavingTime_standardTimeZoneOffset = null;  //For dt_device_isDaylightSavingTime(). No need to refresh each day
	static _dt_server_timeZone                         = null;  //So when we make calcs against dt_now() like diffs, it reports correctly against server. Must be updated via API calls that return server timeZone injections. Also have dt_device_timeZone() & dt_device_isDaylightSavingTime(). WARNING: In EST it's -4 from march to nov and then -5, so not safe to assume it's constant
	
	
	
	
	
	
	/*
	Throws a B_REST_Error instance. We can verify that an err is such w B_REST_Error::isBRESTError()
	Against flags, can:
		-Show an alert(), so we know to open console
		-Force a breakpoint when the console is opened
	Last var is especially for B_REST_VueApp_base's Vue.config.warnHandler()
	WARNING:
		Don't try to implement again a "throwEx_isBubbling" & "throwEx_doneBubbling()", because if we have a pattern like the following:
			try
			{
				...
				try
				{
					...
				}
				catch (e)
				{
					<rethrow>
				}
			}
			catch (e)
			{
				<rethrow>
			}
		The 2nd rethrow won't happen and code execution will continue
		Instead, consider using B_REST_Error.isBRESTError() (and if not enough, maybe add a .toString().indexOf("B_REST_Error") check inside
	*/
	static throwEx(msg, details=null)
	{
		try
		{
			B_REST_Utils._throwEx_count++;
			
			if (B_REST_Utils.flags_onErr_breakpoint) { debugger; }
			
			if (B_REST_Utils.flags_onErr_showNativeAlert===B_REST_Utils.FLAGS_ON_ERR_SHOW_NATIVE_ALERT_ALWAYS || (B_REST_Utils.flags_onErr_showNativeAlert===B_REST_Utils.FLAGS_ON_ERR_SHOW_NATIVE_ALERT_ONCE && B_REST_Utils._throwEx_count===1))
			{
				alert("bREST: Check console for errors");
			}
			
			B_REST_Utils.console_error(msg, details);
		}
		catch (e) { /* Prevent endless loops */ }
		
		throw new B_REST_Error(msg);
	}
		static get throwEx_count() { return B_REST_Utils._throwEx_count; }
	
	/*
	Helper to keep track of TODOs in all files
	To have it auto highlight in VS Code, install https://marketplace.visualstudio.com/items?itemName=fabiospampinato.vscode-highlight and put in settings.json:
		"highlight.regexes": {
			"(IMPORTANT|NOTE)([^\\\n]+)": {
				"filterFileRegex": ".*(js|vue|php)$",
				"regexFlags": "g",
				"decorations": [
					{"color":"#00bb00", "backgroundColor":"transparent", "fontWeight":"bold"},
					{"color":"#00bb00", "backgroundColor":"transparent"},
				]
			},
			"([^_\\w])(WARNING)([^\\\n]+)": {
				"filterFileRegex": ".*(js|vue|php)$",
				"regexFlags": "g",
				"decorations": [
					{"color":"#ff0000", "backgroundColor":"transparent", "fontWeight": "bold"},
					{"color":"#ff0000", "backgroundColor":"transparent"},
				]
			},
			"(console_todo|GenUtils::todos_add)([\\s\\S]+?(?=\\);)\\);)": {
				"filterFileRegex": ".*(js|vue|php)$",
				"regexFlags": "g",
				"decorations": [
					{"color":"#000000", "backgroundColor":"#ffaa00", "fontWeight":"bold", "overviewRulerColor":"#ffaa00"},
					{"color":"#000000", "backgroundColor":"#ffaa00"},
				]
			}
		}
	*/
	static console_todo(msgs)
	{
		B_REST_Utils.array_assert(msgs);
		
		const bulletPoints = `\n\t◌ ${msgs.join("\n\t◌ ")}`;
		
		//Only output todos once
		if (B_REST_Utils._past_todos.includes(bulletPoints)) { return; }
		B_REST_Utils._past_todos.push(bulletPoints);
		
		B_REST_Utils._console_x("flags_console_todo", "todo", bulletPoints);
	}
	static console_info( msg, details=null) { B_REST_Utils._console_x("info",  msg, details); }
	static console_warn( msg, details=null) { B_REST_Utils._console_x("warn",  msg, details); }
	static console_error(msg, details=null) { B_REST_Utils._console_x("error", msg, details); }
		static _console_x(which, msg, details=null)
		{
			try
			{
				const logsEnabled = B_REST_Utils[`flags_console_${which}`];
				
				if (logsEnabled)
				{
					const args = [`B_REST_Utils ${which}>: ${msg}`];
					if (details!==null) { args.push(details); }
					
					console[which].apply(console, args);
					
					//NOTE: Traces displays as console.info, so will be hidden if we filter out the info log lvl
					if (which==="error" && B_REST_Utils.flags_console_error_addStackTrace) { console.trace(); }
				}
			}
			catch (e) { /* Prevent endless loops */ }
		}
	
	
	static get documentBody() { return globalThis.window.document.body; }
	
	
	static assert_formData_support()
	{
		if (!globalThis.window.FormData) { B_REST_Utils.throwEx("FormData class not supported by browser; probably using IE ?! Will cause probs with API calls"); }
	}
	
	
	
	static int_is(val)    { return val!==null && !isNaN(val) && parseInt(val)==parseFloat(val); }
	static number_is(val) { return val!==null && !isNaN(val); }
	static number_assert(val) { if(!B_REST_Utils.number_is(val)){B_REST_Utils.throwEx(`Expected number`);} }
	static number_round(val, decimals)
	{
		B_REST_Utils.number_assert(val);
		B_REST_Utils.number_assert(decimals);
		
		const pow = Math.pow(10,decimals);
		return Math.round((val+Number.EPSILON) * pow) / pow; //As per https://stackoverflow.com/questions/11832914/how-to-round-to-at-most-2-decimal-places-if-necessary
	}
	/*
	Formats a number, by default to the user's locale format, but can be overriden
	Usage ex:
		number_format(123456.789, 2)
			-> 123,456.79
		number_format(123456.789, 2, ".", " ")
			-> 123 456.79
		number_format(123456.789, 2, undefined, undefined, "EUR")
			-> €123,456.79
		number_format(123456.789, 2, undefined, undefined, "CAD")
			-> CA$123,456.79
	NOTE: For currency stuff, we have more params we could handle to control display: https://tc39.es/ecma402/#conformance
	*/
	static number_format(val, decimals=undefined, decimalSep=undefined, thousands=undefined, currency=undefined)
	{
		B_REST_Utils.number_assert(val);
		if (decimals!==undefined) { B_REST_Utils.number_assert(decimals); }
		
		const options = {minimumFractionDigits:decimals, maximumFractionDigits:decimals};
		if (currency)
		{
			options.style    = "currency";
			options.currency = currency;
		}
		
		//If we want to control the separator and thousands, then we have to force it to be parsed as "en-CA" to get "123,456.789"
		const localeTag = decimalSep===undefined && thousands===undefined ? undefined : "en-CA";
		var   formatted = val.toLocaleString(localeTag, options);
		
		if (localeTag)
		{
			formatted = formatted.replaceAll(",", thousands);
			formatted = formatted.replaceAll(".", decimalSep);
		}
		
		return formatted;
	}
	/*
	Same as number_format(), just sorting params another way
	Note that we could handle more currency display options: https://tc39.es/ecma402/#conformance
	WARNING:
		We shouldn't set the 3 last params, as for ex, JPY doesn't have decimals but we do
	*/
	static number_currency(val, currency, decimals=undefined, decimalSep=undefined, thousands=undefined)
	{
		return B_REST_Utils.number_format(val, decimals, decimalSep, thousands, currency);
	}
	
	
	
	static string_is(val) { return typeof(val)==="string"; }
		static string_assert(val)
		{
			if (typeof(val)!=="string") { B_REST_Utils.throwEx("Expected a string"); }
		}
	static string_lcFirst(val) { return B_REST_Utils._string_xcFirst(val,"toLowerCase"); }
	static string_ucFirst(val) { return B_REST_Utils._string_xcFirst(val,"toUpperCase"); }
		static _string_xcFirst(val,methodName) { return val.charAt(0)[methodName]() + val.slice(1); }
	static string_kebab(val) { return val.replaceAll(/([^^])([A-Z])/g,"$1-$2").toLowerCase(); } //Converts "SomeSuperThing" into "some-super-thing". WARNING: Buggy and should be rewritten
	static string_snake(val) { return val.replaceAll(/([^^])([A-Z])/g,"$1_$2").toLowerCase(); } //Converts "SomeSuperThing" into "some_super_thing". WARNING: Buggy and should be rewritten
	//As per https://stackoverflow.com/questions/990904/remove-accents-diacritics-in-a-string-in-javascript
	static string_removeAccents(val)
	{
		return val.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
	}
	//Converts "/some(stuff[to*put+in?regex" into "\/some\(stuff\[to\*put\+in\?regex"
	static string_escapeRegex(val) { return val.replace(/[/\-\\^$*+?.()|[\]{}]/g, '\\$&'); }
	/*
	Converts ex "   Some Thing 124 |    ----- '\/' w accénts   |" into "some-thing-124-w-accents"
	IMPORTANT: Algo must match between server's GenUtils & frontend's B_REST_Utils
	*/
	static string_flatSearchify(val)
	{
		val = B_REST_Utils.string_removeAccents(val);
		val = val.toLowerCase();
		val = val.replaceAll("’","'").replaceAll("«",'"').replaceAll("»",'"'); //NOTE: Actually this isn't relevant for now, as we convert them to "-" after. We could change to [^W'"] though...
		val = val.replace(/[^\w]+/g, "-");
		val = val.replace(/^-|-$/g,  "");
		return val;
	}
	static string_ellipsis(text, maxLength)
	{
		//WARNING: Code actually not as accurate as server's GenUtils::string_ellipsis_x()
		
		return text.length<=maxLength ? text : (text.substring(0,maxLength-4)+B_REST_Utils.ELLIPSIS_PLACEHOLDER_END);
	}
	//Just converts <> and such so they can be seen correctly in an innerHTML
	static string_toHTML_entities(val) { return val.replace(/[\u00A0-\u9999<>\&]/g, loop_char => `&#${loop_char.charCodeAt(0)};`) }
	//Variant where we take \n \t <> and convert to a "pre" way
	static string_toHTML_pre(val)
	{
		const HTML_NL  = "<br />";
		const HTML_TAB = "&#160;".repeat(8);
		const HTML_LT  = "&lt;";
		const HTML_GT  = "&gt;";
		
		return val.replace(/\</g,HTML_LT).replace(/\>/g,HTML_GT).replace(/\n/g,HTML_NL).replace(/\t/g,HTML_TAB);
	}
	
	/*
	Parses a string like the following:
		cieName|coords.<dbOnly>|abc.def(title|firstName|lastName|coords(email|phone|fax|phone2))|ghi.jkl(coords(email|phone|fax|phone2)|title|firstName|lastName)|cientType.name|priceList(name|percent)|invoices(number|date|total)
	And ret an arr like:
		cieName
		coords.<dbOnly>
		abc.def.title
		abc.def.firstName
		abc.def.lastName
		abc.def.title
		abc.def.coords.email
		abc.def.coords.phone
		abc.def.coords.fax
		abc.def.coords.phone2
		ghi.jkl.coords.email
		ghi.jkl.coords.phone
		ghi.jkl.coords.fax
		ghi.jkl.coords.phone2
		ghi.jkl.title
		ghi.jkl.firstName
		ghi.jkl.lastName
		clientType.name
		priceList.name
		priceList.percent
		invoices.number
		invoices.date
		invoices.total
	NOTE:
		We also have the same in backend: GenUtils::splitPipedFieldNamePaths()
	WARNING:
		We support also things like "a(b+c)" and "(a+(b|c)+d)" but output is buggy & it's not clear what we're trying to do (for when we want to simulate a CONCAT(x," ",y))
		Same for bob[3].xyz
	*/
	static splitPipedFieldNamePaths(pipedFieldNamePaths)
	{
		//If we've got nothing (more) to do
		if (pipedFieldNamePaths.indexOf("(")===-1) { return pipedFieldNamePaths.split("|"); }
		
		//First make sure it's balanced, or regex will die
		{
			const length = pipedFieldNamePaths.length;
			let nest = 0;
			for (let i=0; i<length; i++)
			{
				switch (pipedFieldNamePaths[i])
				{
					case "(":
						nest++;
					break;
					case ")":
						nest--;
						if (nest<0) { B_REST_Utils.throwEx(`Nested parens prob at char #${i}, for piped field name path expr "${pipedFieldNamePaths}"`); }
					break;
				}
			}
			if (nest!==0) { B_REST_Utils.throwEx(`Nested parens prob at end of piped field name path expr "${pipedFieldNamePaths}"`); }
		}
		
		//Replace all deepest nested () we find. So if we have (()), we'll have to call this func twice
		{
			const results      = pipedFieldNamePaths.matchAll(/([\w<>\[\]+\.]+)\(([^()]+)\)/g);
			let   loop_current = results.next();
			while (!loop_current.done)
			{
				const loop_area       = loop_current.value[0];                                       //Ex "coords(email+phone|fax|phone2)"
				const loop_prefix     = loop_current.value[1] + ".";                                 //Ex "coords."
				const loop_fields     = loop_current.value[2];                                       //Ex "email+phone|fax|phone2"
				const loop_conversion = loop_prefix + loop_fields.replaceAll("|",`|${loop_prefix}`); //Ex "coords.email+phone|coords.fax|coords.phone2"
				
				pipedFieldNamePaths = pipedFieldNamePaths.replaceAll(loop_area, loop_conversion);
				
				loop_current = results.next();
			}
		}
		
		return B_REST_Utils.splitPipedFieldNamePaths(pipedFieldNamePaths);
	}
	
	
	
	
	
	
	static array_is(val) { return Array.isArray(val); } //NOTE: Works w Proxy of arrs too
	static array_isOfClassInstances(ExpectedClass, val, ifNotThrow=false)
	{
		B_REST_Utils.array_assert(val);
		val.forEach((loop_item,loop_idx) =>
		{
			if (!(loop_item instanceof ExpectedClass))
			{
				if (ifNotThrow) { B_REST_Utils.throwEx(`Didn't find an instance of ${B_REST_Utils.class_name(ExpectedClass)} at arr pos #${loop_idx}`); }
				return false;
			}
		});
		return true;
	}
	static array_isOfObjects(val, ifNotThrow=false)
	{
		B_REST_Utils.array_assert(val);
		for (let i=0; i<val.length; i++)
		{
			if (!B_REST_Utils.object_is(val[i]))
			{
				if (ifNotThrow) { B_REST_Utils.throwEx(`Didn't find an object at arr pos #${i}`); }
				return false;
			}
		}
		return true;
	}
	static array_assert(val)
	{
		if (!B_REST_Utils.array_is(val)) { B_REST_Utils.throwEx("Expected arr"); }
	}
	static array_isOfClassInstances_assert(ExpectedClass, val)
	{
		B_REST_Utils.array_isOfClassInstances(ExpectedClass,val, /*ifNotThrow*/true);
	}
	static array_isOfObjects_assert(val)
	{
		B_REST_Utils.array_isOfObjects(val, /*ifNotThrow*/true);
	}
	//Removes 1 item and returns it. NOTE: If it occurs multiple times in the arr, we should use Array::filter() instead
	static array_remove_byVal(arr, val)
	{
		B_REST_Utils.array_assert(arr);
		
		const idx = arr.indexOf(val);
		if (idx===-1) { return; }
		
		return arr.splice(idx,1)[0]; //NOTE: splice() rets an arr of dropped items
	}
	static array_remove_byIdx(arr, idx)
	{
		B_REST_Utils.array_assert(arr);
		if (idx>=arr.length) { B_REST_Utils.throwEx(`Trying to remove out of arr's bounds: ${idx} / ${arr.length}`); }
		
		return arr.splice(idx,1)[0]; //NOTE: splice() rets an arr of dropped items
	}
	static array_empty(arr)
	{
		B_REST_Utils.array_assert(arr);
		arr.length = 0;
	}
	//Updates actual arr
	static array_unique(arr)
	{
		B_REST_Utils.array_assert(arr);
		const uniques = [];
		for (let i=0; i<arr.length; i++)
		{
			const loop_current = arr[i];
			if (uniques.includes(loop_current))
			{
				arr.splice(i,1);
				i--;
			}
			else { uniques.push(loop_current); }
		}
	}
	/*
	Allows to track when we add/replace/splice etc, by returning a proxy of the arr
	Callback as (action,idx,oldVal=undefined,newVal=undefined), where action is either "set" or "remove"
	We can ret multiple proxies for the same val
	WARNING:
		If we refer to the original arr, the callback won't fire, so we must use what's ret by that func (a Proxy class instance, that also rets true for array_is())
	Usage ex:
		const _privateArr = [1,2,3];
		let   publicArr   = array_setupWatchProxy(_privateArr, () =>
		{
			alert("edited");
		})
		publicArr.push(4);     -> Fires
		publicArr.splice(0,1); -> Fires
		_privateArr.push(5);   -> Doesn't fire
		publicArr = [];        -> Doesn't fire
	About "__proto__":
		Found prob w usage in B_REST_ModelField_DB::_setVal(), because while we expect the callback to only be called on later change, it's called on setup too, so caused validation fuck:
			Ex:
				A req field w proxy starts as NULL, and isn't in the UI, so we can POST the record wo that field, and we get [1,2,3] back from the server
					(ex Model_Event::occurrences_dates in CPA, being calc in server on creation).
				Save gets server response and calls toObj() to pass back ex [1,2,3] to that field, calling _setVal().
				So we'd receive val=[1,2,3] here, then the above "this._validation_type_errorMsg=this._fieldDescriptor.validation_type_errorMsg_eval(val,...)" would run,
					so validation err msg would become NULL.
				But then, right away the data proxy would be re-configurated and fired w the old NULL val, putting back _validation_type_errorMsg to "X is req"
				Figured out that when we setup proxy, it's fired once and we get "__proto__" in the idx param, so we tweaked B_REST_Utils::array_setupWatchProxy()
					to skip firing listener when it's for "__proto__"... which does seem to mean "now". If it doesn't always match, then we'll need to add a prop
					in B_REST_ModelField_DB to say to "return;" at the beginning of _val_arrObjProxy_onAfterChanged() when it's fired right away...
			Fix works at least in Chrome & FF
			Doesn't seem to have the "__proto__" prob in object_setupWatchProxy() though (never found that being called)
	*/
	static array_setupWatchProxy(arr, onChangeCallback)
	{
		B_REST_Utils.array_assert(arr);
		if (!B_REST_Utils.function_is(onChangeCallback)) { B_REST_Utils.throwEx("Expected callback func"); }
		
		return new Proxy(arr, {
			set(target, idx, newVal, receiver)
			{
				const oldVal = target[idx];
				target[idx]  = newVal;
				if (idx!=="__proto__") { onChangeCallback("set",idx,oldVal,newVal); } //Check docs
				return true;
			},
			deleteProperty(target, idx)
			{
				const oldVal = target[idx];
				const newVal = undefined;
				delete target[idx];
				onChangeCallback("remove", idx, oldVal,newVal);
				return true;
			},
		});
	}
	/*
	Deep checks if both point on DIFF mem address, but containing the same thing (works best w primitive vals)
	Will throw if they point on the SAME mem address
	*/
	static array_areEqual(arr1, arr2)
	{
		B_REST_Utils.array_assert(arr1);
		B_REST_Utils.array_assert(arr2);
		if (arr1===arr2) { B_REST_Utils.throwEx(`Received arrs pointing on the SAME mem address; prolly a usage mistake`,arr1); }
		return B_REST_Utils.json_encode(arr1)===B_REST_Utils.json_encode(arr2);
	}
	
	
	
	//NOTE: Works w Proxy of arrs too
	static object_is(val)
	{
		return val instanceof Object && val.constructor.name==="Object"; //NOTE: Class/function instances show as objects too, but their constructor's name won't match to {}
	}
	static object_assert(val)
	{
		if (!B_REST_Utils.object_is(val)) { B_REST_Utils.throwEx("Expected obj"); }
	}
	static object_isEmpty(oObj)
	{
		B_REST_Utils.object_assert(oObj);
		return Object.keys(oObj).length===0;
	}
	/*
	Rets the obj, or an err msg if props aren't valid
	Usage ex:
		object_hasValidStruct(obj, {
			firstName: {accept:[String],         required:true},
			lastName:  {accept:[String,"",null], required:true},
			stuff:     {accept:[Boolean],        required:true},
			other:     {accept:undefined,        default:true},
		});
	Accept types:
		null:      Can be NULL
		"":		   Can be an empty string
		String:	   Can be a non empty string
		Array:     Can be an array, even empty
		Boolean:   Can be true/false
		true:      Can be true
		false:     Can be false
		Number:    Can be int/float
		Object:    Can be a {}, no matter its contained props (for now)
		Function:  Can be a func or async func
		<Class>:   Can be an instance of X (don't specify the class name as string, as when compiled it won't work)
	If we pass no accept types, then it doesn't validate
	If we set a default val, it's used only when undefined (so NULL is considered defined)
	onUseDefaultValsDoShallowCopy:
		In case we must set default vals, we can either alter the received obj or ret another one
	*/
	static object_hasValidStruct(oObj, oStruct, suffix="", onUseDefaultValsDoShallowCopy=true)
	{
		if (suffix) { suffix=`, for ${suffix}`; }
		
		if (!B_REST_Utils.object_is(oStruct)) { return `Struct must be an obj${suffix}`; }
		if (!B_REST_Utils.object_is(oObj))    { return `Didn't get an obj${suffix}`;     }
		
		const invalidPropMsgs = [];
		let   didShallowCopy  = false; //For default vals
		
		for (const loop_propName in oStruct)
		{
			const loop_propConfig = oStruct[loop_propName];
			if (!B_REST_Utils.object_is(loop_propConfig)) { return `Struct for "${loop_propName}" must be an obj w props like {accept,required,default}`; }
			
			const loop_foundVal          = oObj[loop_propName];
			const loop_propAcceptedTypes = oStruct[loop_propName].accept;
			const loop_propIsRequired    = oStruct[loop_propName].required;
			const loop_propDefaultVal    = oStruct[loop_propName].default;
			
			if (loop_foundVal===undefined)
			{
				if (loop_propIsRequired) { invalidPropMsgs.push(`${loop_propName}: Required`); }
				else if (loop_propDefaultVal!==undefined)
				{
					if (onUseDefaultValsDoShallowCopy && !didShallowCopy)
					{
						oObj           = B_REST_Utils.object_copy(oObj, /*bDeep*/false);
						didShallowCopy = true;
					}
					
					oObj[loop_propName] = loop_propDefaultVal;
				}
				continue;
			}
			
			//Check if we don't care whether it's set or not
			if (loop_propAcceptedTypes===undefined) { continue; }
			
			//Else we have to have received a non empty arr
			if (!B_REST_Utils.array_is(loop_propAcceptedTypes) || loop_propAcceptedTypes.length===0) { return `Struct for "${loop_propName}" didn't receive a non-empty arr of accepted types`; }
			
			const loop_trueOrErrMsg = B_REST_Utils.object_hasValidStruct_one(loop_foundVal, loop_propAcceptedTypes); //Rets true or err string
			if (loop_trueOrErrMsg===true) { continue; }
			
			//If we get here, that means this prop isn't valid
			invalidPropMsgs.push(`${loop_propName}: ${loop_trueOrErrMsg}`);
		}
		
		if (invalidPropMsgs.length===0) { return oObj; }
		
		return `Got invalid props${suffix}:\n\t${invalidPropMsgs.join("\n\t")}`;
	}
		//Rets true or an err string
		static object_hasValidStruct_one(val, acceptedTypes)
		{
			const possibilities = [];
			
			for (const loop_acceptedType of acceptedTypes)
			{
				switch (loop_acceptedType)
				{
					case null:
						if (val===null) { return true; }
						possibilities.push("NULL");
					break;
					case "":
						if (val==="") { return true; }
						possibilities.push("Empty string");
					break;
					case String:
						if (B_REST_Utils.string_is(val) && val.length>0) { return true; }
						possibilities.push("Non empty string");
					break;
					case Array:
						if (B_REST_Utils.array_is(val)) { return true; }
						possibilities.push("Arr of any size");
					break;
					case Boolean:
						if (val===true||val===false) { return true; }
						possibilities.push("Bool");
					break;
					case true:
						if (val===true) { return true; }
						possibilities.push("true");
					break;
					case false:
						if (val===false) { return true; }
						possibilities.push("false");
					break;
					case Number:
						if (B_REST_Utils.number_is(val)) { return true; }
						possibilities.push("Number");
					break;
					case Object:
						if (B_REST_Utils.object_is(val)) { return true; }
						possibilities.push("Obj");
					break;
					case Function:
						if (B_REST_Utils.function_is(val)) { return true; }
						possibilities.push("Func/async func");
					break;
					case undefined:
						B_REST_Utils.throwEx(`Don't include [undefined] in object_hasValidStruct_one()`); //Doesn't make sense to do "something:[undefined]", should just do "something:undefined"
					break;
					//Cases for classes
					default:
						if (val instanceof loop_acceptedType) { return true; }
						possibilities.push(B_REST_Utils.class_name(loop_acceptedType));
					break;
				}
			}
			
			return `Must be one of {${possibilities.join("|")}}${val===null?". Got NULL; if this is a setting, you should undefine it or comment it out. Otherwise, consider adding [..,null] in the allowed types for that prop":""}`;
		}
	static object_hasValidStruct_assert(oObj, oStruct, suffix, onUseDefaultValsDoShallowCopy=true)
	{
		if (!suffix) { B_REST_Utils.throwEx(`Specify struct suffix`); }
		const result = B_REST_Utils.object_hasValidStruct(oObj, oStruct, suffix, onUseDefaultValsDoShallowCopy);
		
		if (B_REST_Utils.string_is(result)) { B_REST_Utils.throwEx(result, {expected:oStruct,received:oObj}); }
		return result; //Original obj or shallow copied one
	}
	static object_hasPropName(oObj, sPropName)
	{
		B_REST_Utils.object_assert(oObj);
		return Object.keys(oObj).includes(sPropName.toString()); //Because we could receive an int
	}
	static object_hasPropVal(oObj, val)
	{
		B_REST_Utils.object_assert(oObj);
		return Object.values(oObj).includes(val);
	}
	/*
	Adds / overwrites props to oObj, from another obj. Should be used with primitive vals
	Usage ex:
		const oObj = {a:1, b:2};
		B_REST_Utils.object_addProps(oObj, {c:3});
		 -> {a:1, b:2, c:3}
	Also rets the final obj (only useful if we did something like B_REST_Utils.object_addProps({}, ...)
	*/
	static object_addProps(oObj, oOtherProps)
	{
		B_REST_Utils.object_assert(oObj);
		return Object.assign(oObj, oOtherProps);
	}
	static object_removeProp(oObj, sPropName) { delete oObj[sPropName]; }
	//Copies an obj in a shallow or deep manner. Deep can throw exceptions with circular refs
	static object_copy(oObj, bDeep)
	{
		B_REST_Utils.object_assert(oObj);
		
		if (bDeep)
		{
			try
			{
				//NOTE: Might fail if there's circular refs
				return B_REST_Utils.json_decode(B_REST_Utils.json_encode(oObj));
			}
			catch (e)
			{
				B_REST_Utils.throwEx(`Got error while copying with json_x(): "${e}"`);
			}
		}
		//Shallow copy
		else
		{
			return B_REST_Utils.object_addProps({}, oObj);
		}
	}
	/*
	Allows to track when we set/remove props, by returning a proxy of the obj
	Callback as (action,key,oldVal=undefined,newVal=undefined), where action is either "set" or "remove"
	We can ret multiple proxies for the same val
	WARNING:
		If we refer to the original obj, the callback won't fire, so we must use what's ret by that func (a Proxy class instance, that also rets true for object_is())
		Doesn't deep watch
	Usage ex:
		const _privateObj = {a,b,c};
		let   publicObj   = object_setupWatchProxy(_privateObj, () =>
		{
			alert("edited");
		})
		publicObj.a = {};             -> Fires
		publicObj.a.deepStuff = 123;  -> Doesn't fire
		publicObj.d = 4;              -> Fires
		delete publicObj.b;           -> Fires
		publicObj = {};               -> Doesn't fire
	About "__proto__": Check array_setupWatchProxy() docs. For now, could never find a case where obj proxy contains a "__proto__" key.
		Maybe because for arr, Array is a class w constructor...
	*/
	static object_setupWatchProxy(obj, onChangeCallback)
	{
		B_REST_Utils.object_assert(obj);
		if (!B_REST_Utils.function_is(onChangeCallback)) { B_REST_Utils.throwEx("Expected callback func"); }
		
		return new Proxy(obj, {
			set(target, key, newVal, receiver)
			{
				const oldVal = target[key];
				target[key]  = newVal;
				if (key==="__proto__") { B_REST_Utils.throwEx(`Didn't expect object_setupWatchProxy() to have the "__proto__" prob`); } // Check array_setupWatchProxy() docs and fix code
				onChangeCallback("set",key,oldVal,newVal); //Check docs
				return true;
			},
			deleteProperty(target, key)
			{
				const oldVal = target[key];
				const newVal = undefined;
				delete target[key];
				onChangeCallback("remove", key, oldVal,newVal);
				return true;
			},
		});
	}
	/*
	Deep checks if both point on DIFF mem address, but containing the same thing (works best w primitive vals)
	Will throw if they point on the SAME mem address, or if they contain recursion
	*/
	static object_areEqual(obj1, obj2)
	{
		B_REST_Utils.object_assert(obj1);
		B_REST_Utils.object_assert(obj2);
		if (obj1===obj2) { B_REST_Utils.throwEx(`Received objs pointing on the SAME mem address; prolly a usage mistake`,obj1); }
		return B_REST_Utils.json_encode(obj1)===B_REST_Utils.json_encode(obj2);
	}
	
	
	
	/*
	Works with:
		function(){}
		async function(){}
		()=>{}
		async()=>{}
	*/
	static function_is(val)
	{
		val = val?.constructor?.name;
		return val==="Function" || val==="AsyncFunction";
	}
	
	
	
	static class_name(ClassPtr) { return ClassPtr.prototype.constructor.name; }
	/*
	Usage ex:
		class Base
		{
			static baseFunc()
			{
				const DerClass = B_REST_Utils.class_ptr_fromBaseStaticFunc(this);
			}
		};
		
		class Der extends Base {};
		
		Der.baseFunc();
	*/
	static class_ptr_fromBaseStaticFunc(staticThis) { return staticThis.prototype.constructor; }
	
	
	
	static instance_is(val)
	{
		return val instanceof Object && val.constructor.name!=="Object" && !val.prototype; //NOTE: Class/function instances show as objects too, but their constructor's name won't match to {}
	}
	static instance_assert(val)
	{
		if (!B_REST_Utils.instance_is(val)) { B_REST_Utils.throwEx("Expected an instance of some class"); }
	}
	//NOTE: Don't have a instance_isOfClass() func, just use the instanceof op and be careful about neg like "!(x instanceof ClassName)"
	static instance_isOfClass_assert(ExpectedClass, val)
	{
		if (!(val instanceof ExpectedClass)) { B_REST_Utils.throwEx(`Expected an instance of ${B_REST_Utils.class_name(ExpectedClass)}`); }
	}
	static instance_className(val)
	{
		B_REST_Utils.instance_assert(val);
		return val.constructor.name;
	}
	
	
	
	static dom_is(val) { return val instanceof Element; }
	static dom_assert(val)
	{
		if (!B_REST_Utils.dom_is(val)) { B_REST_Utils.throwEx("Expected a DOM elem"); }
	}
	
	
	
	static dt_is(dt) { return dt instanceof Date; }
	//It's possible to do new Date("bob"), so we must make sure it's working
	static dt_isValid(dt)
	{
		B_REST_Utils._dt_assert(dt);
		return !isNaN(dt.getTime());
	}
		static dt_assert_isValid(dt)
		{
			if (!B_REST_Utils.dt_isValid(dt)) { B_REST_Utils.throwEx("Expected an instance of Date + being valid"); }
		}
			static _dt_assert(dt)
			{
				if (!(dt instanceof Date)) { B_REST_Utils.throwEx("Expected an instance of Date"); }
			}
	//Check dt_fromYmdAndOrHis() docs for accepted formats
	static dt_asString_isValid(val)
	{
		B_REST_Utils.string_assert(val);
		try
		{
			B_REST_Utils.dt_fromYmdAndOrHis(val);
			return true;
		}
		catch (e) { return false; }
	}
	//Rets the nb of days in a given month. 2 signatures, either (Date) or (year,month) where month is 1-based
	static dt_daysInMonth(dtOrYear, nullOrMonth=null)
	{
		let year  = null;
		let month = null;
		
		if (dtOrYear instanceof Date)
		{
			if (nullOrMonth!==null) { B_REST_Utils.throwEx("Only pass month when dtOrYear is int"); }
			year  = dtOrYear.getFullYear();
			month = dtOrYear.getMonth()+1; //1-based var
		}
		else if (B_REST_Utils.int_is(dtOrYear))
		{
			if (nullOrMonth===null) { B_REST_Utils.throwEx("Need month when dtOrYear is int"); }
			year  = dtOrYear;
			month = nullOrMonth; //1-based var
		}
		else { B_REST_Utils.throwEx("Expected year"); }
		
		return new Date(year,month,0, 0,0,0).getDate(); //JS months start from 0, so if we pass 1 for jan, it'll think it's feb, and we do 0th day, which points to the day before feb 1st
	}
	/*
	Adds / subtracts months. Rets a new Date instance
	Behavior for ifStartIsLastDay_clip
		false:
			No matter the starting day of the month, get to target month and reduce the day if it doesn't fit in the month
			Ex:
				2023-02-28 +1 = 2023-03-28
				2023-01-31 +1 = 2023-02-28
				2023-01-31 +3 = 2023-04-30
				2023-04-30 +1 = 2023-05-30 (not 05-31)
		true:
			If starting day of the month happens to be the last of -that- month, then no matter the target month, always ret its last day
			Also lower if we don't start on the last day but still is too high for the target month
			Ex:
				2023-02-28 +1 = 2023-03-31
				2023-01-31 +1 = 2023-02-28
				2023-01-30 +1 = 2023-02-28
				2023-01-28 +1 = 2023-02-28
				2023-01-30 +2 = 2023-03-30
				2023-01-28 +2 = 2023-03-28
				2023-04-30 +1 = 2023-05-31 (not 05-30
	*/
	static dt_deltaMonths(dt, delta, ifStartIsLastDay_clip=false)
	{
		B_REST_Utils.dt_assert_isValid(dt);
		
		const start_day = dt.getDate();
		const mustClip  = ifStartIsLastDay_clip && start_day === B_REST_Utils.dt_daysInMonth(dt);
		
		const copy = new Date(Number(dt));
		copy.setDate(1);
		copy.setMonth(copy.getMonth()+delta);
		
		const target_lastDay = B_REST_Utils.dt_daysInMonth(copy);
		copy.setDate(mustClip||start_day>=target_lastDay ? target_lastDay : start_day);
		
		return copy;
	}
	//Adds / subtracts days. Rets a new Date instance
	static dt_deltaDays(dt, delta)
	{
		B_REST_Utils.dt_assert_isValid(dt);
		
		const copy = new Date(Number(dt));
		copy.setDate(copy.getDate() + delta);
		
		return copy;
	}
	//Adds / subtracts minutes. Rets a new Date instance
	static dt_deltaMinutes(dt, delta)
	{
		B_REST_Utils.dt_assert_isValid(dt);
		
		const copy = new Date(Number(dt));
		copy.setMinutes(copy.getMinutes() + delta);
		
		return copy;
	}
	//Adds / subtracts seconds. Rets a new Date instance
	static dt_deltaSeconds(dt, delta)
	{
		B_REST_Utils.dt_assert_isValid(dt);
		
		const copy = new Date(Number(dt));
		copy.setSeconds(copy.getSeconds() + delta);
		
		return copy;
	}
	//Ex "2023-06-01 12:34:56" becomes "2023-06-01 00:00:00" or "2023-06-01 23:59:59". Rets a new Date instance
	static dt_shiftTime_00_00_00(dt) { return B_REST_Utils._dt_shiftTime_H_i_s(dt, 0, 0, 0); }
	static dt_shiftTime_23_59_59(dt) { return B_REST_Utils._dt_shiftTime_H_i_s(dt,23,59,59); }
		static _dt_shiftTime_H_i_s(dt, hours, minutes, seconds)
		{
			B_REST_Utils.dt_assert_isValid(dt);
			
			const copy = new Date(Number(dt));
			copy.setHours(hours);
			copy.setMinutes(minutes);
			copy.setSeconds(seconds);
			
			return copy;
		}
	/*
	Ex "2023-06-01 12:34:56" becomes "2023-05-28 00:00:00" or "2023-06-03 23:59:59". Rets a new Date instance
	Other use cases could imply using for calendar displaying weeks from monday to the -next- sunday instead of sunday to saturday, so doing 1 to 7 instead of 0 to 6
	*/
	static dt_shiftDateTime_week_sunday_00_00_00(dt)   { return B_REST_Utils.dt_shiftDateTime_week_H_i_s(dt,0, 0, 0, 0); }
	static dt_shiftDateTime_week_saturday_23_59_59(dt) { return B_REST_Utils.dt_shiftDateTime_week_H_i_s(dt,6,23,59,59); }
		static dt_shiftDateTime_week_H_i_s(dt, sundayOffset, hours, minutes, seconds)
		{
			const copy = B_REST_Utils._dt_shiftTime_H_i_s(dt,hours,minutes,seconds);
			copy.setDate(copy.getDate()-copy.getDay() + sundayOffset);
			
			return copy;
		}
	//Ex "2024-02-06 12:34:56" becomes "2024-02-01 00:00:00" or "2024-02-29 23:59:59". Rets a new Date instance
	static dt_shiftDateTime_month_first_00_00_00(dt) { return B_REST_Utils._dt_shiftDateTime_month_x_H_i_s(dt,"first", 0, 0, 0); }
	static dt_shiftDateTime_month_last_23_59_59(dt)  { return B_REST_Utils._dt_shiftDateTime_month_x_H_i_s(dt,"last", 23,59,59); }
		static _dt_shiftDateTime_month_x_H_i_s(dt, which, hours, minutes, seconds)
		{
			const copy = B_REST_Utils._dt_shiftTime_H_i_s(dt,hours,minutes,seconds);
			if (which==="first") { copy.setDate(1); }
			else
			{
				copy.setMonth(copy.getMonth()+1);
				copy.setDate(0); //Days start at 1, so 0 means "the day before 1st day of the month"
			}
			
			return copy;
		}
	//Rets days diff between dt_from and dt_to. If both days are equal, rets 0 even if time is different
	static dt_daysDiff(dt_from, dt_to)
	{ 
		B_REST_Utils.dt_assert_isValid(dt_from);
		B_REST_Utils.dt_assert_isValid(dt_to);
		
		// Ignore His, all we need are the dates
		const d_from = new Date(dt_from.getFullYear(), dt_from.getMonth(), dt_from.getDate(), 0,0,0);
		const d_to   = new Date(dt_to.getFullYear(),   dt_to.getMonth(),   dt_to.getDate(),   0,0,0);
		
		return Math.round((d_to-d_from) / B_REST_Utils.DT_MSECS_IN_DAY); //WARNING: Must round, otherwise sometimes we'd get 123.0000000001
	}
	//Rets minutes diff between dt_from and dt_to. Ignores seconds, so "00:00:00" vs "00:00:59" eval to 0 and not 1 (0.99)
	static dt_minutesDiff(dt_from, dt_to)
	{
		B_REST_Utils.dt_assert_isValid(dt_from);
		B_REST_Utils.dt_assert_isValid(dt_to);
		
		const dt_from_clipped = new Date(dt_from.getFullYear(), dt_from.getMonth(), dt_from.getDate(), dt_from.getHours(), dt_from.getMinutes(), 0);
		const dt_to_clipped   = new Date(dt_to.getFullYear(),   dt_to.getMonth(),   dt_to.getDate(),   dt_to.getHours(),   dt_to.getMinutes(),   0);
		
		return Math.round((dt_to_clipped-dt_from_clipped) / B_REST_Utils.DT_MSECS_IN_MINUTE); //WARNING: Must round, otherwise sometimes we'd get 123.0000000001
	}
	//Rets secs diff between dt_from and dt_to
	static dt_secondsDiff(dt_from, dt_to)
	{
		B_REST_Utils.dt_assert_isValid(dt_from);
		B_REST_Utils.dt_assert_isValid(dt_to);
		
		return Math.round((dt_to-dt_from) / B_REST_Utils.DT_MSECS_IN_SECOND); //WARNING: Must round, otherwise sometimes we'd get 123.0000000001
	}
	
	/*
	Ex if now is "2023-05-02 13:31:00" and we pass "2023-05-27 18:25:00", yields:
		{years:0, months:0, days:25, hours:4, minutes:54, seconds:0}
	We can also clip negative durations to ret 0, or continue below and ret neg parts
	WARNING:
		For now, seconds always ret 0, because we have no dt_secondsDiff() func yet and it's prolly not relevant
		Not accurate for months & years; doesn't account for 28~31 days months and 364~365 days years, leap seconds etc
	*/
	static dt_timePartsBetween(dt_from, dt_to, allowNeg)
	{
		B_REST_Utils.dt_assert_isValid(dt_from);
		B_REST_Utils.dt_assert_isValid(dt_to);
		
		const parts = {years:0, months:0, days:0, hours:0, minutes:0, seconds:0};
		
		const isFlipped = dt_from>dt_to;
		if (isFlipped && !allowNeg) { return parts; }
		
		//Easier to always deal w positive parts, and flip back after
		if (isFlipped)
		{
			const old_dt_from = dt_from;
			dt_from = dt_to;
			dt_to   = old_dt_from;
		}
		
		let remainingSecondsUntil = B_REST_Utils.dt_secondsDiff(dt_from, dt_to);
		
		parts.years   = Math.floor(remainingSecondsUntil/B_REST_Utils.DT_SECS_IN_AVG_YEAR);  remainingSecondsUntil -= parts.years*B_REST_Utils.DT_SECS_IN_AVG_YEAR;
		parts.months  = Math.floor(remainingSecondsUntil/B_REST_Utils.DT_SECS_IN_AVG_MONTH); remainingSecondsUntil -= parts.months*B_REST_Utils.DT_SECS_IN_AVG_MONTH;
		parts.days    = Math.floor(remainingSecondsUntil/B_REST_Utils.DT_SECS_IN_DAY);       remainingSecondsUntil -= parts.days*B_REST_Utils.DT_SECS_IN_DAY;
		parts.hours   = Math.floor(remainingSecondsUntil/B_REST_Utils.DT_SECS_IN_HOUR);      remainingSecondsUntil -= parts.hours*B_REST_Utils.DT_SECS_IN_HOUR;
		parts.minutes = Math.floor(remainingSecondsUntil/B_REST_Utils.DT_SECS_IN_MINUTE);    remainingSecondsUntil -= parts.minutes*B_REST_Utils.DT_SECS_IN_MINUTE;
		parts.seconds = remainingSecondsUntil;
		
		if (isFlipped)
		{
			parts.years   = -parts.years;
			parts.months  = -parts.months;
			parts.days    = -parts.days;
			parts.hours   = -parts.hours;
			parts.minutes = -parts.minutes;
			parts.seconds = -parts.seconds;
		}
		
		return parts;
	}
	//Helpers of most of the above, with target dt / dt_to being now
		/*
		Rets adjusted to server time
		WARNING:
			Not good, because internally, JS dates are stored as seconds since unix, so bREST API calls should ret dates as UTC seconds instead of YmdHis
				+ still has refs to new Date() outside of B_REST_Utils, instead of dt_now()
			But for now, doing this because in CPA we have a 15 mins timer and working from JP fucked
		*/
		static dt_now()
		{
			const serverTimeZoneDiff = B_REST_Utils._dt_server_timeZone - B_REST_Utils.dt_device_timeZone; //NOTE: The latter is a getter but actually not constant
			
			const dt = new Date();
			if (serverTimeZoneDiff) { dt.setHours(dt.getHours()+serverTimeZoneDiff); }
			return dt;
		}
		static dt_now_Ymd()                                                 { return B_REST_Utils.dt_format(B_REST_Utils.dt_now(),true, false);                                                              }
		static dt_now_YmdHis()                                              { return B_REST_Utils.dt_format(B_REST_Utils.dt_now(),true, true,true);                                                          }
		static dt_now_His()                                                 { return B_REST_Utils.dt_format(B_REST_Utils.dt_now(),false,true,true);                                                          }
		static dt_now_Hi()                                                  { return B_REST_Utils.dt_format(B_REST_Utils.dt_now(),false,true,false);                                                         }
		static dt_now_weekday()                                             { return B_REST_Utils.dt_now().getDay();                                                                                         } //0=sunday
		static dt_now_deltaMonths(delta,ifStartIsLastDay_clip=false)        { return B_REST_Utils.dt_deltaMonths(B_REST_Utils.dt_now(),delta,ifStartIsLastDay_clip);                                         }
		static dt_now_deltaMonths_Ymd(delta,ifStartIsLastDay_clip=false)    { return B_REST_Utils.dt_format(B_REST_Utils.dt_deltaMonths( B_REST_Utils.dt_now(),delta,ifStartIsLastDay_clip),true,false);     }
		static dt_now_deltaMonths_YmdHis(delta,ifStartIsLastDay_clip=false) { return B_REST_Utils.dt_format(B_REST_Utils.dt_deltaMonths( B_REST_Utils.dt_now(),delta,ifStartIsLastDay_clip),true,true,true); }
		static dt_now_deltaDays(delta)                                      { return                        B_REST_Utils.dt_deltaDays(   B_REST_Utils.dt_now(),delta);                                       }
		static dt_now_deltaDays_Ymd(delta)                                  { return B_REST_Utils.dt_format(B_REST_Utils.dt_deltaDays(   B_REST_Utils.dt_now(),delta),true,false);                           }
		static dt_now_deltaDays_YmdHis(delta)                               { return B_REST_Utils.dt_format(B_REST_Utils.dt_deltaDays(   B_REST_Utils.dt_now(),delta),true,true,true);                       }
		static dt_now_deltaMinutes(delta)                                   { return                        B_REST_Utils.dt_deltaMinutes(B_REST_Utils.dt_now(),delta);                                       }
		static dt_now_deltaMinutes_Ymd(delta)                               { return B_REST_Utils.dt_format(B_REST_Utils.dt_deltaMinutes(B_REST_Utils.dt_now(),delta),true,false);                           }
		static dt_now_deltaMinutes_YmdHis(delta)                            { return B_REST_Utils.dt_format(B_REST_Utils.dt_deltaMinutes(B_REST_Utils.dt_now(),delta),true,true,true);                       }
		static dt_now_deltaSeconds(delta)                                   { return                        B_REST_Utils.dt_deltaSeconds(B_REST_Utils.dt_now(),delta);                                       }
		static dt_now_deltaSeconds_Ymd(delta)                               { return B_REST_Utils.dt_format(B_REST_Utils.dt_deltaSeconds(B_REST_Utils.dt_now(),delta),true,false);                           }
		static dt_now_deltaSeconds_YmdHis(delta)                            { return B_REST_Utils.dt_format(B_REST_Utils.dt_deltaSeconds(B_REST_Utils.dt_now(),delta),true,true,true);                       }
		static dt_now_daysDiff(dt_from)                                     { return B_REST_Utils.dt_daysDiff(        dt_from,B_REST_Utils.dt_now());                                                        }
		static dt_now_minutesDiff(dt_from)                                  { return B_REST_Utils.dt_minutesDiff(     dt_from,B_REST_Utils.dt_now());                                                        }
		static dt_now_secondsDiff(dt_from)                                  { return B_REST_Utils.dt_secondsDiff(     dt_from,B_REST_Utils.dt_now());                                                        }
		static dt_now_timePartsBetween(dt_from,allowNeg)                    { return B_REST_Utils.dt_timePartsBetween(dt_from,B_REST_Utils.dt_now(),allowNeg);                                               }
	static dt_fromYmdHis(val) { return B_REST_Utils._dt_fromX(val, /*shouldHaveDate*/true, /*shouldHaveTime*/true,  /*stripDate*/false,/*stripTime*/false); } //Seconds are optional
	static dt_fromYmd(val)    { return B_REST_Utils._dt_fromX(val, /*shouldHaveDate*/true, /*shouldHaveTime*/false, /*stripDate*/false,/*stripTime*/null);  }
	static dt_fromHis(val)    { return B_REST_Utils._dt_fromX(val, /*shouldHaveDate*/false,/*shouldHaveTime*/true,  /*stripDate*/null, /*stripTime*/false); } //Seconds are optional
	static dt_fromYmdAndOrHis(val, stripDate=false, stripTime=false) //Seconds are optional
	{
		return B_REST_Utils._dt_fromX(val, /*shouldHaveDate*/null,/*shouldHaveTime*/null, stripDate,stripTime);
	}
		/*
		Rets a Date obj
		Accepts the following formats:
			Y-m-d
			Y-m-d H:i
			Y-m-d H:i:s
			Y-m-d H:i:s.123Z
			Y-m-d H:i:s.123Z+05:00
			Y-m-dTH:i
			Y-m-dTH:i:s
			Y-m-dTH:i:s.123Z
			Y-m-dTH:i:s.123Z+05:00
			H:i
			H:i:s
			H:i:s.123Z
			H:i:s.123Z+05:00
		Doesn't accept formats like "12 jan" or "01/01/01" because fuck off
		Check https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date#date_time_string_format for details
		Throws if:
			-We needed a date part and we didn't get one, or got one when we shouldn't
			-We needed a time part and we didn't get one, or got one when we shouldn't
			-The resulting date isn't valid (ex year below DT_LOWEST_JS_YEAR, or digits out of range)
		Last 2 params are for discarding received parts, ex for "1111-11-11 11:11:11":
			stripDate: "1896-01-01 11:11:11" (DT_LOWEST_JS_YEAR)
			stripTime: "1111-11-11 00:00:00"
		*/
		static _dt_fromX(val, shouldHaveDate,shouldHaveTime, stripDate,stripTime)
		{
			if (shouldHaveDate===false && shouldHaveTime===false) { B_REST_Utils.throwEx(`Can't expect no date AND no time`); }
			if (stripDate && stripTime)                           { B_REST_Utils.throwEx(`Can't strip date AND time`);        }
			
			B_REST_Utils.string_assert(val);
			const parts = val.match(/^((\d\d\d\d)-(\d\d)-(\d\d)([T ])?)?(([0-5]\d):([0-5]\d)(:([0-5]\d).*)?)?$/);
			if (!parts) { B_REST_Utils.throwEx(`Invalid date format #1`); }
			
			const hasDate = parts[1]!==undefined;
			if (shouldHaveDate!==null && shouldHaveDate!==hasDate) { B_REST_Utils.throwEx(`Invalid date format #2`); }
			const hasTime = parts[6]!==undefined;
			if (shouldHaveTime!==null && shouldHaveTime!==hasTime) { B_REST_Utils.throwEx(`Invalid date format #3`); }
			const hasSeparator = parts[5]!==undefined;
			if ((hasDate&&hasTime) !== hasSeparator) { B_REST_Utils.throwEx(`Invalid date format #4`); } //So if we get like "Y-m-dT", "Y-m-d " or "Y-m-dH:i:s" it'll fuck
			
			let Y = B_REST_Utils.DT_LOWEST_JS_YEAR;
			let m = 0; //0-base. Look the note below
			let d = 1; //IMPORTANT: Not a typo that it's set to 1 and not 0; make it by default equal to (B_REST_Utils.DT_LOWEST_JS_YEAR,0,1) (1896-01-01)
			let H = 0;
			let i = 0;
			let s = 0;
			
			if (hasDate && !stripDate)
			{
				Y = parseInt(parts[2]);
				m = parseInt(parts[3])-1;
				d = parseInt(parts[4]);
			}
			if (hasTime && !stripTime)
			{
				H = parseInt(parts[7]);
				i = parseInt(parts[8]);
				if (parts[10]!==undefined) { s=parseInt(parts[10]); }
			}
			
			const dt = new Date(Y,m,d, H,i,s);
			if (isNaN(dt.getTime())) { B_REST_Utils.throwEx(`Invalid date format #5`); }
			
			//NOTE: Regex validates digit ranges for time, but we can't for date, and the isNaN check isn't so good, so make sure the date didn't shift out
			if (dt.getMonth()!==m || dt.getDate()!==d) { B_REST_Utils.throwEx(`Invalid date format #6`); }
			
			return dt;
		}
	static dt_format(dt, wDate, wTime, wSeconds=false, dtSeparator=" ") //Sep can be "T" as well
	{
		if (!wTime)
		{
			if (!wDate)   { B_REST_Utils.throwEx(`Can't ask for no date AND no time`); }
			if (wSeconds) { B_REST_Utils.throwEx(`Can't ask for seconds when !wTime`); }
		}
		B_REST_Utils.dt_assert_isValid(dt);
		
		let ret = "";
		
		if (wDate)
		{
			const y = dt.getFullYear();
			const m = dt.getMonth()+1;
			const d = dt.getDate();
			
			ret += `${y}-${m<10?"0"+m:m}-${d<10?"0"+d:d}`;
		}
		
		if (wTime)
		{
			if (ret!=="") { ret+=dtSeparator; }
			
			const h = dt.getHours();
			const i = dt.getMinutes();
			
			ret += `${h<10?"0"+h:h}:${i<10?"0"+i:i}`;
			
			if (wSeconds)
			{
				const s = dt.getSeconds();
				
				ret += `:${s<10?"0"+s:s}`;
			}
		}
		
		return ret;
	}
		static dt_toYmd(dt)    { return B_REST_Utils.dt_format(dt,true, false);      }
		static dt_toYmdHis(dt) { return B_REST_Utils.dt_format(dt,true, true,true);  }
		static dt_toYmdHi(dt)  { return B_REST_Utils.dt_format(dt,true, true,false); }
		static dt_toHis(dt)    { return B_REST_Utils.dt_format(dt,false,true,true);  }
		static dt_toHi(dt)     { return B_REST_Utils.dt_format(dt,false,true,false); }
		//0=sunday
		static dt_toWeekday(dt)
		{
			B_REST_Utils.dt_assert_isValid(dt);
			return dt.getDay();
		}
	//Rets as Unix timestamp, but without milliseconds (like PHP)
	static dt_u(dt)
	{
		B_REST_Utils.dt_assert_isValid(dt);
		return Math.floor(dt.getTime() / 1000);
	}
	/*
	Checks if a date in our location is under daylight saving or not. Ex in EST it's -4 from march to nov (daylight saving time) and then -5 from nov to march (normal)
	As per https://stackoverflow.com/questions/11887934/how-to-check-if-dst-daylight-saving-time-is-in-effect-and-if-so-the-offset
	*/
	static dt_device_isDaylightSavingTime(dt)
	{
		B_REST_Utils.dt_assert_isValid(dt);
		
		//NOTE: No need to refresh this algo each day
		if (B_REST_Utils._dt_device_isDaylightSavingTime_standardTimeZoneOffset===null)
		{
			const currentYear = new Date().getFullYear();
			const january_1st = new Date(currentYear,0,1, 0,0,0);
			const july_1st    = new Date(currentYear,6,1, 0,0,0);
			B_REST_Utils._dt_device_isDaylightSavingTime_standardTimeZoneOffset = Math.max(january_1st.getTimezoneOffset(), july_1st.getTimezoneOffset());
		}
		
		return dt.getTimezoneOffset() < B_REST_Utils._dt_device_isDaylightSavingTime_standardTimeZoneOffset;
	}
	/*
	Check var docs for more info (ex on EST time toggling between 4 to 5 in frontend but not server)
	Also have dt_device_isDaylightSavingTime()
	WARNING: In EST it's -4 from march to nov and then -5, so not safe to assume it's constant
	*/
	static set dt_server_timeZone(val) { B_REST_Utils._dt_server_timeZone=val;    }
	static get dt_server_timeZone()    { return B_REST_Utils._dt_server_timeZone; }
	//NOTE: We need to eval each time, as if we leave the app open for more than a day, we could flip between daylight saving and it could ret for ex -4 instead of -5
	static get dt_device_timeZone()    { return -new Date().getTimezoneOffset()/60; }
	
	//For info, check server's CryptoUtils
	static hash(val,salt) { return sha512(`${val}${salt}`); }
	
	/*
	We do [raw pwd] > [frontend to backend encryption] > [db encryption]
	So API calls never show raw pwd
	Ex "pwd" -> "<intermediate>6a4b49f07b599056dc1dc08d2c68afd8c2dd49af1c346fb51c7d8d56576354a6e2608e8e161151cb92886e4fbde45ac9e4c1a69bbcf0566cce108abc0200e60a"
	For more info, check server's CryptoUtils
	NOTE:
		There's also a new native API, but the prob is that it's async: https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest#basic_example
	*/
	static pwd_raw_toFrontendHash(pwd_raw, salt)
	{
		if (!pwd_raw) { B_REST_Utils.throwEx(`Received empty pwd`); } //NOTE: Anyways, we don't want someone's whose pwd is just '0'
		
		const hashed = B_REST_Utils.hash(pwd_raw, salt);
		
		return `${B_REST_Utils.PWD_FRONTEND_TAG}${hashed}`;
	}
	
	
	static contentType_evalFromData(data)
	{
		if (data===null)                  { return B_REST_Utils.CONTENT_TYPE_EMPTY;     }
		if (typeof(data) === "string")    { return B_REST_Utils.CONTENT_TYPE_TEXT;      }
		if (data instanceof FormData)     { return B_REST_Utils.CONTENT_TYPE_FORM_DATA; }
		if (B_REST_Utils.object_is(data)) { return B_REST_Utils.CONTENT_TYPE_JSON;      }
		if (B_REST_Utils.array_is(data))  { return B_REST_Utils.CONTENT_TYPE_JSON;      }
		
		B_REST_Utils.throwEx("Unexpected data content type",data);
	}
	//Ex checks if it matches "application/pdf,image/*"
	static contentType_matches(expected, received)
	{
		expected = expected.toLowerCase();
		received = received.toLowerCase();
		
		if (expected===B_REST_Utils.CONTENT_TYPE_ANYTHING || expected===received) { return true; } //NOTE: Also works when we expect B_REST_Utils.CONTENT_TYPE_EMPTY
		
		const expected_parts = expected.split(",");
		
		for (let i=0; i<expected_parts.length; i++)
		{
			const loop_expected_part = expected_parts[i];
			
			//Ex "application/pdf"
			if (received===loop_expected_part) { return true; }
			//Ex "image/*"
			else if (loop_expected_part.indexOf("/*")!==-1)
			{
				if (received.indexOf(loop_expected_part.replace("*",""))===0) { return true; }
			}
		}
		
		return false;
	}
	
	
	
	static async sleep(msecs)
	{
		return new Promise((resolve,reject) =>
		{
			setTimeout(resolve, msecs);
		});
	}
	
	
	
	/*
	Usage ex:
		makeUID(32, "bob-")
			-> "bob-74d56e569b0d3d952f019e39380c"
	*/
	static makeUID(length, prefix="")
	{
		//Yields an arr of vals like 1762511923
		const randomVals = globalThis.window.crypto.getRandomValues(new Uint32Array(length)); //NOTE: We should have enough with about 1/8 of the length, but just in case we get small numbers like 0, x times
		
		//Converts to hex
		const uid = randomVals.reduce((acc,loop_val) => acc+loop_val.toString(16), prefix);
		
		return uid.substr(0,length);
	}
	
	
	
	/*
	Ex we got:
		const a = Symbol("bob")
		Yields "bob"
	*/
	static symbolVal(symbol) { return symbol.description; }
	
	
	
	/*
	Check backend's Model_base::_field_parseFieldNamePath() docs
	For B_REST_Request_base::data_set() & B_REST_Response::data_getFieldData()
	Usage ex:
		const {self_fieldName, atIdx, target_fieldNameOrExpr} = parseFieldNamePath("favorites[123].product.name");
			self_fieldName:         "favorites"
			atIdx:                  123
			target_fieldNameOrExpr: "product.name"
	*/
	static parseFieldNamePath(fieldNameExpr)
	{
		if (!B_REST_Utils.string_is(fieldNameExpr)) { B_REST_Utils.throwEx(`Expected string`); }
		
		const match = fieldNameExpr.match(/^(([^\.\[]+)(\[(\d+)\])?)(\.(.+))?$/);
		if (!match) { B_REST_Utils.throwEx(`Got no field name expr`); }
		
		return {
			self_fieldName:         match[2]!==undefined                  ? match[2]           : null, //Ex "favorites"
			atIdx:                  match[4]!==undefined && match[4]!=='' ? parseInt(match[4]) : null, //Ex 123. Can be null
			target_fieldNameOrExpr: match[6]!==undefined                  ? match[6]           : null, //Ex "product.name"
		};
	}
				
	
	
	//FILES STUFF
		static get FILE_SIZE_KB() { return 1000;    }
		static get FILE_SIZE_MB() { return 1000000; }
		static files_humanReadableSize(byteSize)
		{
			if (byteSize >= B_REST_Utils.FILE_SIZE_MB) { return (byteSize/B_REST_Utils.FILE_SIZE_MB).toFixed(2) + " mb"; }
			if (byteSize >= B_REST_Utils.FILE_SIZE_KB) { return (byteSize/B_REST_Utils.FILE_SIZE_KB).toFixed(2) + " kb"; }
			return `${byteSize} bytes`;
		}
		
		static get FILES_MIME_PATTERNS_DANGEROUS() { return ".php,.php1,.php2,.php3,.php4,.php5,.php6,.php7,.php8,.php9,.pht,.phtml,.shtml,.asa,.cer,.asax,.swf,.xap,.sh,.bin,.htaccess,.exe"; }
		static get FILES_MIME_PATTERNS_IMG()       { return "image/*"; }
		static get FILES_MIME_PATTERNS_PDF()       { return "application/pdf,.pdf"; }
		static get FILES_MIME_PATTERNS_WORD()      { return "application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.doc,.docx"; }
		static get FILES_MIME_PATTERNS_PDF_WORD()  { return `${B_REST_Utils.FILES_MIME_PATTERNS_PDF},${B_REST_Utils.FILES_MIME_PATTERNS_WORD}`; }
		static get FILES_MIME_PATTERNS_EXCEL()     { return "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xls,.xlsx"; }
		static get FILES_MIME_PATTERNS_ANY()       { return "*.*"; }
		
		/*
		Ex checks if it matches "application/pdf,.docx,image/*". Can use common string like B_REST_Utils.FILES_MIME_PATTERNS_PDF_WORD
		Case insensitive
		*/
		static files_mime_matchesPattern(mimeOrExt, pattern)
		{
			if (!pattern || pattern===B_REST_Utils.FILES_MIME_PATTERNS_ANY) { return true; }
			
			if (!mimeOrExt) { B_REST_Utils.throwEx(`Got no mime or ext`); }
			mimeOrExt = mimeOrExt.toLowerCase();
			
			const patternParts = pattern.split(",");
			
			for (let i=0; i<patternParts.length; i++)
			{
				const loop_patternPart = patternParts[i];
				
				//Ex "application/pdf"
				if (mimeOrExt===loop_patternPart) { return true; }
				//Ex ".pdf"
				else if (loop_patternPart.indexOf(".")===0)
				{
					if (mimeOrExt===loop_patternPart.replace(".","")) { return true; }
				}
				//Ex "image/*"
				else if (loop_patternPart.indexOf("/*")!==-1)
				{
					if (mimeOrExt.indexOf(loop_patternPart.replace("*",""))===0) { return true; }
				}
			}
			
			return false;
		}
			static files_mime_isDangerous(mimeOrExt) { return B_REST_Utils.files_mime_matchesPattern(mimeOrExt,B_REST_Utils.FILES_MIME_PATTERNS_DANGEROUS); }
			static files_mime_isImg(mimeOrExt)       { return B_REST_Utils.files_mime_matchesPattern(mimeOrExt,B_REST_Utils.FILES_MIME_PATTERNS_IMG);       }
			static files_mime_isPdf(mimeOrExt)       { return B_REST_Utils.files_mime_matchesPattern(mimeOrExt,B_REST_Utils.FILES_MIME_PATTERNS_PDF);       }
			static files_mime_isWord(mimeOrExt)      { return B_REST_Utils.files_mime_matchesPattern(mimeOrExt,B_REST_Utils.FILES_MIME_PATTERNS_WORD);      }
			static files_mime_isPdfOrWord(mimeOrExt) { return B_REST_Utils.files_mime_matchesPattern(mimeOrExt,B_REST_Utils.FILES_MIME_PATTERNS_PDF_WORD);  }
			static files_mime_isExcel(mimeOrExt)     { return B_REST_Utils.files_mime_matchesPattern(mimeOrExt,B_REST_Utils.FILES_MIME_PATTERNS_EXCEL);     }
		//Funcs to take a full file name w ext, and either get the name or ext alone
		static files_baseNameToName(baseNameWExt) { return baseNameWExt ? baseNameWExt.split(".")[0]                  : null; }
		static files_baseNameToExt(baseNameWExt)  { return baseNameWExt ? baseNameWExt.split(".").pop().toLowerCase() : null; }
		//Rets NULL if we can't figure it out. Case insensitive
		static files_extToMime(ext)
		{
			if (!ext) { return null; }
			
			switch (ext.toLowerCase())
			{
				case "bmp":                           return "image/bmp";
				case "gif":                           return "image/gif";
				case "jpeg": case "jpg": case "jfif": return "image/jpeg";
				case "png":                           return "image/png";
				case "svg":                           return "image/svg+xml";
				case "pdf":                           return "application/pdf";
				case "doc":                           return "application/msword";
				case "docx":                          return "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
				case "xls":                           return "application/vnd.ms-excel";
				case "xlsx":                          return "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
			}
			
			return null;
		}
		//Rets NULL if we can't figure it out, or throws if we get nothing. Case insensitive
		static files_mimeToExt(mime)
		{
			if (!mime) { B_REST_Utils.throwEx(`Got no mime`); }
			
			switch (mime.toLowerCase())
			{
				case "image/bmp":                                                               return "bmp";
				case "image/gif":                                                               return "gif";
				case "image/jpeg": case "image/jpg": case "image/jfif":                         return "jpeg";
				case "image/png":                                                               return "png";
				case "image/svg+xml":                                                           return "svg";
				case "application/pdf":                                                         return "pdf";
				case "application/msword":                                                      return "doc";
				case "application/vnd.openxmlformats-officedocument.wordprocessingml.document": return "docx";
				case "application/vnd.ms-excel":                                                return "xls";
				case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":       return "xlsx";
			}
			
			return null;
		}
		/*
		Usage ex:
			For bREST_Response:
				const objectURL = files_objectURL_create(response.data, response.data_contentType);
			Also check B_REST_DOMFilePtr::to_objectURL() & B_REST_DOMFilePtr::to_img()
		Expects data to be raw data, native Blob/File instance
		WARNING:
			After the objectURL is used, we must use files_objectURL_revoke() to release memory
		*/
		static files_objectURL_create(data, contentType=null)
		{
			if (!(data instanceof Blob)) //NOTE: File extends Blob
			{
				if (!B_REST_Utils.string_is(data)) { B_REST_Utils.throwEx(`Expected Blob/File or raw string data`); }
				
				const mustBecomeBinary = contentType==="application/pdf";
				
				let blobView = null;
				if (mustBecomeBinary)
				{
					//NOTE: We have similar code in B_REST_Utils::files_objectURL_create(), B_REST_DOMFilePtr::_base64DataURL_toU8Arr() & B_REST_DOMFilePtr::to_blob()
					
					data = atob(data);
					const dataLength = data.length;
					
					blobView = new Uint8Array(new ArrayBuffer(dataLength));
					for (let i=0; i<dataLength; i++) { blobView[i]=data.charCodeAt(i); }
				}
				else { blobView=data; }
				
				data = new Blob([blobView], {type:contentType});
			}
			
			return globalThis.window.URL.createObjectURL(data, {type:contentType});
		}
		//Can be called multiple times
		static files_objectURL_revoke(objectURL)
		{
			globalThis.window.URL.revokeObjectURL(objectURL);
		}
	
	
	
	//LOCAL STORAGE RELATED
		/*
		Returns if we have permissions, but doesn't indicate if there is really still space remaining
		https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
		*/
		static localStorage_isAvailable()
		{
			if (!!globalThis.localStorage) { return false; }
			
			try
			{
				var x = "__localStorage_test__";
				globalThis.localStorage.setItem(x, x);
				globalThis.localStorage.removeItem(x);
				return true;
			}
			catch (e)
			{
				//Check _localStorage_isQuotaExceededError() docs for WTF
				
				if (B_REST_Utils._localStorage_isQuotaExceededError(e))
				{
					B_REST_Utils._localStorage_logError(e);
					return false;
				}
				
				return true;
			}
		}
			static _localStorage_assertAvailable()
			{
				if (!B_REST_Utils.localStorage_isAvailable) { B_REST_Utils.throwEx(`Local storage not available`); }
			}
			static _localStorage_parseError(error, reThrow=true)
			{
				let type = null;
				
				if      (B_REST_Utils._localStorage_isSecurityError(error))      { type="Security";   }
				else if (B_REST_Utils._localStorage_isQuotaExceededError(error)) { type="Quota";      }
				else                                                             { type="Unexpected"; }
				
				B_REST_Utils._localStorage_logError(error);
				
				const msg = `Local storage error: ${type}`;
				
				if (reThrow) { B_REST_Utils.throwEx(msg); }
				return msg;
			}
				static _localStorage_logError(error)
				{
					B_REST_Utils.throwEx(`Local storage not available`,error);
				}
				//As per https://developer.mozilla.org/en-US/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API
				static _localStorage_isQuotaExceededError(error)
				{
					if (error instanceof DOMException)
					{
						//NOTE: Now browsers don't use the "code" prop anymore, and use the "name" instead
						const isLikeQuotaError = (error.code===22 || error.code===1014 || error.name==="QuotaExceededError" || error.name==="NS_ERROR_DOM_QUOTA_REACHED");
						// acknowledge QuotaExceededError only if there's something already stored
						return isLikeQuotaError && globalThis.localStorage.length>0;
					}
					
					return false;
				}
				static _localStorage_isSecurityError(error)
				{
					return error instanceof DOMException && error.name==="SecurityError";
					//NOTE: Probably has more cases, but we don't know yet
				}
		/*
		Only rets bREST ones. Rets as map of key => {isPersistent}, ex if:
			bREST:locale_lang=fr
			bREST-p:custom_acceptCookies=<true>
		Rets:
			{
				locale_lang:          {isPersistent:false},
				custom_acceptCookies: {isPersistent:true},
			}
		*/
		static localStorage_getKeys()
		{
			B_REST_Utils._localStorage_assertAvailable();
			
			const bRESTKeys = {};
			
			try
			{
				const allKeys   = Object.keys(globalThis.localStorage); //NOTE: We don't do a for (x in y) because it'd ret props like "getItem", "setItem"...
				const regExp    = new RegExp(`^(${B_REST_Utils.LOCAL_STORAGE_PREFIX}|${B_REST_Utils.LOCAL_STORAGE_PREFIX_PERSISTENT})(.*)$`);
				for (const loop_rawKeyName of allKeys)
				{
					const loop_parts = loop_rawKeyName.match(regExp);
					if (!loop_parts) { continue; }
					
					const loop_key          = loop_parts[2];
					const loop_isPersistent = loop_parts[1]===B_REST_Utils.LOCAL_STORAGE_PREFIX_PERSISTENT;
					
					bRESTKeys[loop_key] = {isPersistent:loop_isPersistent};
				}
			}
			catch (e) { B_REST_Utils._localStorage_parseError(e,/*reThrow*/true); }
			
			return bRESTKeys;
		}
		//Of bREST ones
		static localStorage_isEmpty()
		{
			const bRESTKeys = B_REST_Utils.localStorage_getKeys();
			return B_REST_Utils.object_isEmpty(bRESTKeys);
		}
		/*
		Works even w persistent ones, but doesn't tell if they are persistent
		Optimization note:
			We could use localStorage_getKeys() to avoid doing 2 calls when it should be persistent,
			but if we have 100 keys and only 1 persistent, it's a waste
		*/
		static localStorage_get(key, throwIfNull=true)
		{
			B_REST_Utils._localStorage_assertAvailable();
			
			let data = null;
			
			try
			{
				data = globalThis.localStorage.getItem(`${B_REST_Utils.LOCAL_STORAGE_PREFIX}${key}`);
				if (data===null) { data=globalThis.localStorage.getItem(`${B_REST_Utils.LOCAL_STORAGE_PREFIX_PERSISTENT}${key}`); } //Maybe it's a persistent one
			}
			catch (e) { B_REST_Utils._localStorage_parseError(e,/*reThrow*/true); }
			
			if (data===null && throwIfNull) { B_REST_Utils.throwEx(`Storage key "${key}" not found`); }
			
			switch (data)
			{
				case B_REST_Utils.LOCAL_STORAGE_UNDEFINED: data=undefined; break;
				case B_REST_Utils.LOCAL_STORAGE_NULL:      data=null;      break;
				case B_REST_Utils.LOCAL_STORAGE_TRUE:      data=true;      break;
				case B_REST_Utils.LOCAL_STORAGE_FALSE:     data=false;     break;
			}
			
			return data;
		}
		//Works even w persistent ones, but doesn't tell if they are persistent
		static localStorage_has(key)
		{
			const data = B_REST_Utils.localStorage_get(key, /*throwIfNull*/false); //Throws if LS not available though
			return data!==null;
		}
		//Set persistent if for ex it should stay even when we log out, like cookies acceptation
		static localStorage_set(key, data, isPersistent)
		{
			B_REST_Utils._localStorage_assertAvailable();
			if (isPersistent===undefined) { B_REST_Utils.throwEx(`Must tell if must be persistent`); }
			
			switch (data)
			{
				case undefined: data=B_REST_Utils.LOCAL_STORAGE_UNDEFINED; break;
				case null:      data=B_REST_Utils.LOCAL_STORAGE_NULL;      break;
				case true:      data=B_REST_Utils.LOCAL_STORAGE_TRUE;      break;
				case false:     data=B_REST_Utils.LOCAL_STORAGE_FALSE;     break;
			}
			
			const prefix = isPersistent ? B_REST_Utils.LOCAL_STORAGE_PREFIX_PERSISTENT : B_REST_Utils.LOCAL_STORAGE_PREFIX;
			try       { globalThis.localStorage.setItem(`${prefix}${key}`,data);   }
			catch (e) { B_REST_Utils._localStorage_parseError(e,/*reThrow*/true); }
		}
		//Works even w persistent ones. Doesn't throw if not found
		static localStorage_remove(key)
		{
			B_REST_Utils._localStorage_assertAvailable();
			
			try
			{
				globalThis.localStorage.removeItem(`${B_REST_Utils.LOCAL_STORAGE_PREFIX}${key}`);
				globalThis.localStorage.removeItem(`${B_REST_Utils.LOCAL_STORAGE_PREFIX_PERSISTENT}${key}`);
			}
			catch (e) { B_REST_Utils._localStorage_parseError(e,/*reThrow*/true); }
		}
		//Only for bREST ones. Optionnally also clearing persistent ones
		static localStorage_clear(clearPersistentOnes)
		{
			B_REST_Utils._localStorage_assertAvailable();
			if (clearPersistentOnes===undefined) { B_REST_Utils.throwEx(`Must tell if must be persistent`); }
			
			const bRESTKeys = B_REST_Utils.localStorage_getKeys(); //Already throws correctly
			
			try
			{
				for (const loop_key in bRESTKeys)
				{
					const {isPersistent:loop_isPersistent} = bRESTKeys[loop_key];
					
					let loop_prefix = B_REST_Utils.LOCAL_STORAGE_PREFIX;
					if (loop_isPersistent)
					{
						if (!clearPersistentOnes) { continue; }
						loop_prefix = B_REST_Utils.LOCAL_STORAGE_PREFIX_PERSISTENT;
					}
					
					globalThis.localStorage.removeItem(`${loop_prefix}${loop_key}`);
				}
			}
			catch (e) { B_REST_Utils._localStorage_parseError(e,/*reThrow*/true); }
		}
	
	
	
	//URL RELATED
		static url_current_domainName(wPort) { return wPort ? window.location.host : window.location.hostname; } //Ex "localhost:8080" vs "localhost"
		static url_current_domainNameWProtocol() { return `${window.location.protocol}//${B_REST_Utils.url_current_domainName(true)}`; }
		//Where "https://<domainName>/a/b/123?bob=456" yields "/a/b/123", optionally adding ?bob=456 if wQSA=true
		static url_current_getAbsPath(wQSA) { return `${window.location.pathname}${wQSA?window.location.search:''}`; }
		//Say we where on an external URL that lead here. If we use Vue-router, will stay the same no matter how we navigate inside the app, as long as we don't F5
		static get url_referrer() { return Document.referrer ?? null; }
		/*
		For a path on the app. Rets as {path, qsa:{}, hashtag:null}
		Works even if received path was like "/leads/{pkTag}"
		*/
		static url_getInfo(fullPath)
		{
			const urlInfo = new URL(fullPath, `https://${window.location.hostname}`);
			const qsa     = Object.fromEntries(urlInfo.searchParams);
			const hashTag = urlInfo.hash.replace("#","") || null;
			const path    = urlInfo.pathname.replaceAll("%7B","{").replaceAll("%7D","}");
			
			return {path, qsa, hashTag};
		}
		/*
		For a path on the app
		Usage ex:
			url_removeQSA("/some/path?a=123&b=456#someTag", "b")
				-> "/some/path?a=123#someTag"
			url_removeQSA("/some/path?a=123#someTag", "a")
				-> "/some/path#someTag"
		*/
		static url_removeQSA(fullPath, which)
		{
			const urlInfo = new URL(fullPath, `https://${window.location.hostname}`);
			
			return B_REST_Utils._url_removeQSA_fromURLSearchParams(urlInfo, urlInfo.searchParams, which);
		}
			//Expects a native instance of URL
			static _url_removeQSA_fromURLSearchParams(locationOrUrl, urlSearchParams, which)
			{
				if (!(locationOrUrl instanceof URL) && !(locationOrUrl instanceof Location)) { B_REST_Utils.throwEx(`Expected instance of URL or Location`,locationOrUrl); }
				B_REST_Utils.instance_isOfClass_assert(URLSearchParams, urlSearchParams);
				
				urlSearchParams.delete(which);
				
				let qsa = urlSearchParams.toString();
				if (qsa!=="") { qsa=`?${qsa}`; }
				
				return `${locationOrUrl.pathname}${qsa}${locationOrUrl.hash}`;
			}
		//Ex if we were on "/login?_sh=a8s90dg0a", we'd set which to "_sh" to get "a8s90dg0a"
		static url_current_getQSA(which)
		{
			const qsa = new URLSearchParams(location.search);
			return qsa.has(which) ? qsa.get(which) : null;
		}
		/*
		Ex if we were on "/login?_sh=a8s90dg0a", we could call this to end up w just "/login"
		Check url_removeQSA() docs
		*/
		static url_current_removeQSA(which)
		{
			const newUrl = B_REST_Utils._url_removeQSA_fromURLSearchParams(location, new URLSearchParams(location.search), which);
			
			history.replaceState(null, '', newUrl);
		}
		/*
		Ex we had "/some/url" and pass qsa:{a:1,b:2} & hashTag:bob, would yield
			/some/url?a=1&b=2#bob
		*/
		static url_addQSAAndHashTag(url, qsa={}, hashTag=null)
		{
			const qsaString = B_REST_Utils.url_qsaToString(qsa);
			if (qsaString) { url+=`?${qsaString}`; }
			
			if (hashTag!==null) { url+=`#${hashTag}`; }
			
			return url;
		}
		//Ex {a:1,b:2} would become "a=1&b=2". If we receive an empty obj, yields NULL
		static url_qsaToString(qsa={})
		{
			B_REST_Utils.object_assert(qsa);
			
			const qsaObj = new URLSearchParams();
			for (const loop_qsaParamName in qsa)
			{
				qsaObj.append(loop_qsaParamName, qsa[loop_qsaParamName]);
			}
			
			const qsaString = qsaObj.toString();
			return qsaString!=="" ? qsaString : null;
		}
	
	
	
	//CLIPBOARD RELATED
		static async clipboard_write(contents) { await navigator.clipboard.writeText(contents); }
	
	
	
	//JSON RELATED
		//Skips circular refs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Errors/Cyclic_object_value#circular_references
		static json_encode(objOrArrOrNull, prettify=false)
		{
			try
			{
				const includedObjList = []; //NOTE: Snippet indicates to use a WeakSet instead of an arr
				return JSON.stringify(objOrArrOrNull, (loop_key,loop_val) =>
				{
					if (loop_val!==null && typeof loop_val==="object")
					{
						if (includedObjList.includes(loop_val)) { return; }
						includedObjList.push(loop_val);
					}
					return loop_val;
				}, prettify?"\t":null);
			}
			catch (e) { B_REST_Utils.throwEx(`Caught err while trying to encode json: ${e}`,objOrArrOrNull); }
		}
		static json_decode(json, isJSONWComments=false)
		{
			if (isJSONWComments) { json = json.replace(/\\"|"(?:\\"|[^"])*"|(\/\/.*|\/\*[\s\S]*?\*\/)/g, (m,g)=>g?"":m); } //https://stackoverflow.com/questions/33483667/how-to-strip-json-comments-in-javascript
			try       { return JSON.parse(json);                                                   }
			catch (e) { B_REST_Utils.throwEx(`Caught err while trying to decode json: ${e}`,json); }
		}
		/*
		Usage ex:
			json_escape(`"some"\n\tthing`)
				->
					`"\\"some\\"\\n\\tthing"`
			json_escape(123)
				->
					`123`
			json_escape(null)
				->
					`null`
		NOTE:
			Always ret a string, and if it was a string, adds "" around
		*/
		static json_escape(val) { return JSON.stringify(val); }
};
