<template>
	<br-generic-form-base :derived-component="_self">
		
		<template #fields>
			<v-row>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="name" :disabled="!$bREST.roles_isAnyRoleAdmin" /> </v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="corpoShortName" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="ownerNames" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="contactEmail" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="phone" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="address" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="postalCode" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="configCity_fk" items="configCityList"/> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="configAdministrativeRegion_fk" items="configAdministrativeRegionList"/> </v-col>
				<v-col v-if="!NO_MORE_REG_FEES" cols="12" md="6">
					<br-field-db :model="model" field="presentialRegFees" suffix=" $" /> 
					<div class="red--text font-italic">{{ t("presentialRegFeesTip") }}</div>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="6"><v-text-field :value="modelField_urls.fbGroup"   @change="modelField_urls.fbGroup  =$event; on_urls_change()" outlined rounded dense :label="t('urls.fbGroup')"   /> </v-col>
				<v-col cols="12" md="6"><v-text-field :value="modelField_urls.fbPage"    @change="modelField_urls.fbPage   =$event; on_urls_change()" outlined rounded dense :label="t('urls.fbPage')"    /> </v-col>
				<v-col cols="12" md="6"><v-text-field :value="modelField_urls.instagram" @change="modelField_urls.instagram=$event; on_urls_change()" outlined rounded dense :label="t('urls.instagram')" /> </v-col>
			</v-row>
			<v-row>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="regConfirmMsg" for-lang="fr" as="textarea"/> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="regConfirmMsg" for-lang="en" as="textarea"/> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="hqFranchisee_is" as="checkbox" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="marketingTag" /> </v-col>
				<v-col cols="12" md="6"> <br-field-db :model="model" field="taxNumber_fed" /> </v-col>
				<v-col v-if="taxNumber_prov_has" cols="12" md="6"> <br-field-db :model="model" field="taxNumber_prov" /> </v-col>
				<!-- 🚀↑app>modules>x>XForm.vue>template>fields↑🚀 -->
			</v-row>
			
			<v-row>
				<v-col cols="12">
					<v-card>
						<v-card-title>{{ t('parks') }}</v-card-title>
						<v-card-text>
							<br-generic-form-base-sub-model-list :form="self" route-names="franchisee>franchiseePark" :accordion="false" />
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12"> <div class="font-italic red--text">{{ t("upcomingStuff") }}</div> </v-col>
			</v-row>
			
		</template>
		
		<!-- 🚀↑app>modules>x>XForm.vue>template>slots↑🚀 -->
		
		
		
	</br-generic-form-base>
</template>

<script>
	
	import { B_REST_Vuetify_GenericForm } from "@/bREST/core/implementations/vue";
	
	const NO_MORE_REG_FEES = true; //As of 2024-05, instead of combining a Model_Session::priceGrid w Model_Franchisee::presentialRegFees, we only have Model_FranchiseeSessionInfo::priceGrid
	
	
	
	export default {
		name: "franchiseeForm",
		components: {
			/* 🚀↑app>modules>x>XForm.vue>js>components↑🚀 */
		},
		mixins: B_REST_Vuetify_GenericForm.createMixin({
			modelName:          "Franchisee",
			apiBaseUrl:         "/franchisees/",
			showValidationErrs: true,    //🚀❓ If we want red err msgs near the save btn at the bottom of the form ❓🚀
			showSkeletonLoader: true,    //🚀❓ While we load an existing record, do we want a grey "skeleton" overlay ? ❓🚀
		  //autoUpdateInterval: 2000,    //🚀❓ If we want that blurring fields auto save modifs every X msecs ❓🚀
			requiredFields:     "<all>", //🚀❓ Check server's ModelOptions_base.php docs for "fieldNamePath" possibilities ❓🚀
			todos: [
				//🚀❓ Arr of {isDone,isBug,text} that will appear automatically at the top of the form ❓🚀
			],
			async modelReady()
			{
				/*
					🚀❓
						When a new record, called right on component mount.
						When an existing record, called after it's done loading & afterLoad() hook done
						Usage ex, where we also adjust for possible cases where we'd get a parent pk
							if (this.model.isNew)
							{
								//For when route is like /citizens/:citizen/animals/:pkTag, or we're from a BrGenericListBase::openFormInVDialog(), etc. Can get better cue parent_modelName or parent_routeName. Check props docs
								if (this.parent_pkTag) { this.model.select("citizen_fk").val=this.parent_pkTag; }
							}
					🚀❓
				*/
				
				this._setup_json_fields();
			},
			async afterLoad(response,models)                 { }, //🚀❓ When an existing record, a hook where we still have the API B_REST_Response available to pimp the model. Called before modelReady() ❓🚀
			async customValidator()                          { }, //🚀❓ Called at the beginning of awaitUnsavedChangesSaved_x() to check if it's ok to save. Can also be called manually. Should put customErrorList.x_add/x_if() here. Check BrGenericFormBase.vue::customErrors_x() docs or how we use it ex in UserForm.vue or MyProfile.vue ❓🚀
			async beforeSave(request,model)                  { }, //🚀❓ When we want to save, a hook so we can pimp the API B_REST_Request_x that will be sent to the server ❓🚀
			async afterSave(response,model,isSuccess,wasNew) { }, //🚀❓ Like afterLoad(), we just saved and we still have access to the API B_REST_Response, to do extra stuff ❓🚀
		}),
		data()
		{
			return {
				NO_MORE_REG_FEES,
				dataSets: {
					/* 🚀↑app>modules>x>XForm.vue>js>dataSets↑🚀 */
				},
			};
		},
		computed: {
			taxNumber_prov_has() { return this.$bREST.businessConfig.businessTag==="cpa"; },
			is_hq()              { return this.model.select("hqFranchisee_is").val;       },
			modelField_urls()    { return this.model.select("urls").val;                  },
		},
		methods: {
			_setup_json_fields()
			{
				const modelField_urls = this.model.select("urls");
				
				// need to init json to non-null in case
				if (modelField_urls.val===null) { modelField_urls.val={fbGroup:null,fbPage:null,instagram:null}; }
			},
			// notify json field that it has been modified
			on_urls_change()
			{
				this.model.select("urls").unsavedChanges_flag(false);
			},
		},
	};
	
</script>